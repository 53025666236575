<template>
  <main
    id="content"
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
    <div
      class="page-questions__header d-flex justify-content-between align-items-center nav-secondary"
      style="color: #fff; height: 60px"
    >
      <router-link :to="'/lista-prova-correcao/' + this.id" class="link fs-24">
        <i class="fas fa-chevron-left mr-3" /> Provas
      </router-link>
      <ul class="list-inline m-0">
        <li class="list-inline-item mr-4">
          <button
            style="width: 100px"
            class="btn btn-classic"
              id="salvarProvaCorrecao"

            @click.prevent="salvar"
          >
            Salvar
          </button>
        </li>
      </ul>
    </div>

    <div
      class="page-questions__header d-flex justify-content-between align-items-center"
    >
      <div class="row " style="margin: auto">
        <div class="col-md-12">
          <ul class="list-inline m-0">
            <li class="list-inline-item mr-4">
              Questões Para Corrigir
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="page-questions__content">
      <!-- Start Content center -->
      <div class="content-right px-4" style="margin: auto">
        <div
          class="card card-questions mb-4"
          style="border: 6px solid #ddd;padding: 0;"
        >
          <div class="card-body mb-3" style="padding: 0;">
            <div style="padding: 20px;">
              <div class="row">
                <!--Dados Da Prova-->
                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label
                      >Aluno
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"/></label
                    ><br />
                    <input
                      type="texts"
                      class="form-control"
                      v-bind:readonly="true"
                      v-model="prova_aluno.aluno.nome"
                    />
                  </div>
                </div>
                <!--Dados Da Questão-->
                <div class="col-md-4">
                  <div class="form-group full-select">
                    <label for="disciplina1"
                      >Componente Curricular
                      <i
                        class="help ml-1"
                        data-toggle="tooltip"
                        title="Some tooltip text!"
                    /></label>
                    <input
                      type="texts"
                      class="form-control"
                      v-bind:readonly="true"
                      v-model="prova_aluno.disciplina"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="peso"
                      >Título
                      <i class="far fa-question-circle" style="color: #D2D2D2;"
                    /></label>
                    <input
                      id="peso"
                      v-model="prova_aluno.prova.titulo"
                      v-bind:readonly="true"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-mb-12" style="padding: 20px;">
              <questao-list-corrigir
                v-for="(questao, index) in questoes"
                :key="questao.questao.id"
                :questao-prop="questao"
                :aqui="index + 1"
                :is-list-prova="true"
                :is-list-inserida-prova="false"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- End Content center -->
    </div>
  </main>
</template>

<script>
import Swal from "sweetalert2";
import store from "@/store";
import QuestaoListCorrigir from "@/components/questoes/QuestaoListCorrigir";

export default {
  components: {
    QuestaoListCorrigir,
  },
  data() {
    return {
      questoes: [],
      id: null,
      questoesCorrecao: [],
      prova_aluno: {
        aluno: { nome: "" },
        prova: { notaMaxima: "" },
      },
    };
  },
  created() {
    this.$root.$emit("Spinner::show");
    this.loadQuestaoProva();
    this.id = this.$route.params.prova;
  },
  methods: {
    salvar() {
        const data = {
          prova: this.$route.params.prova,
          aluno: this.$route.params.aluno,
          questoes: this.questoesCorrecao
        }
        this.$api
          .post("/provas-corrigir/" + data.prova, data)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Resposta salva com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(function() {
              window.location.reload()
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    registraCorrecao(questao) {
      // Se já estava registrada a questão, tira ela do array e coloca com os dados atuais
      this.questoesCorrecao = this.questoesCorrecao.filter(quest => quest.questao != questao.questao);
      this.questoesCorrecao.push(questao);
      console.log(this.questoesCorrecao);
    },
    loadQuestaoProva() {
      this.$api
        .get(
          "/resultado-prova-aluno/" +
            this.$route.params.prova +
            "/" +
            this.$route.params.aluno
        )
        .then((response) => {
          response.data.respostas.forEach((element) => {
            if (element.tipo == "Descritiva" && element.resposta != null) {
              this.questoes.push(element);
            }
          });
          this.prova_aluno = response.data;
          console.log(this.prova_aluno);
        });

      this.$root.$emit("Spinner::hide");
    },
  },
};
</script>

<style scoped>
.ck-editor__editable {
  min-height: 5000px;
}
</style>
